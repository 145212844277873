<template>
  <div>

    <div
      class="text-center"
      :class="serviceProvider?'cursor-pointer':''"
      @click="serviceProvider?handleProviderViewActionClick(user):false"
    >
      <profile-avatar :user="user" />
      <profile-name :user="user" />
      <profile-languages :user="user" />
    </div>

    <profile-phones
      :user="user"
      :service-provider="serviceProvider"
    />
    <profile-share-button
      :user="user"
      class="text-right"
    />
    <profile-service-offers
      :user="user"
      :short="true"
      class="pt-1"
    />

    <!--    <hr class="mb-2">-->

    <!-- follower projects rank -->
    <!--    <div class="d-flex justify-content-between align-items-center">-->
    <!--      <div>-->
    <!--        <h6 class="text-muted font-weight-bolder">-->
    <!--          Followers-->
    <!--        </h6>-->
    <!--        <h3 class="mb-0">-->
    <!--          10.3k-->
    <!--        </h3>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <h6 class="text-muted font-weight-bolder">-->
    <!--          Projects-->
    <!--        </h6>-->
    <!--        <h3 class="mb-0">-->
    <!--          156-->
    <!--        </h3>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <h6 class="text-muted font-weight-bolder">-->
    <!--          Rank-->
    <!--        </h6>-->
    <!--        <h3 class="mb-0">-->
    <!--          23-->
    <!--        </h3>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--/ follower projects rank -->

  </div>
</template>

<script>

import ProfileServiceOffers from '@/views/pages/profile/ProfileServiceOffers.vue'
import ProfilePhones from '@/views/pages/profile/ProfilePhones.vue'
import ProfileLanguages from '@/views/pages/profile/ProfileLanguages.vue'
import ProfileShareButton from '@/views/pages/profile/ProfileShareButton.vue'
import ProfileAvatar from '@/views/pages/profile/ProfileAvatar.vue'
import ProfileName from '@/views/pages/profile/ProfileName.vue'
import { useUserUi } from '@/views/apps/user/useUser'

export default {
  components: {
    ProfileServiceOffers,
    ProfilePhones,
    ProfileLanguages,
    ProfileShareButton,
    ProfileAvatar,
    ProfileName,
  },

  props: {
    user: {
      type: Object,
      default: () => {},
      required: true,
    },
    serviceProvider: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  methods: {

  },
  setup() {
    const { handleProviderViewActionClick } = useUserUi()
    return {
      handleProviderViewActionClick,
    }
  },
}

</script>
<style scoped>

</style>
