<template>
  <div
    id="point-details"
    :class="point.post_status ==='closed'?'overlay':''"
  >
    <!--    <b-row>-->
    <!--      <b-col-->
    <!--        lg="8"-->
    <!--      >-->
    <!--    <b-row>-->

    <!--            <b-media-->
    <!--              no-body-->
    <!--              class="mb-1"-->
    <!--            >-->
    <!--              <b-media-aside class="mr-1">-->
    <!--                <b-avatar-->
    <!--                  rounded-->
    <!--                  variant="light-primary"-->
    <!--                  size="34"-->
    <!--                >-->
    <!--                  <feather-icon-->
    <!--                    icon="CalendarIcon"-->
    <!--                    size="18"-->
    <!--                  />-->
    <!--                </b-avatar>-->
    <!--              </b-media-aside>-->
    <!--              <b-media-body>-->
    <!--                <h6 class="mb-0">-->
    <!--                  <small>{{ 'from ' }}</small>{{ '12.12.2022' }}<br>-->
    <!--                  <small>{{ 'to ' }}</small>{{ '13.12.2022' }}-->
    <!--                </h6>-->
    <!--              </b-media-body>-->
    <!--              <b-media-aside class="mr-1">-->
    <!--                <b-avatar-->
    <!--                  rounded-->
    <!--                  variant="light-primary"-->
    <!--                  size="34"-->
    <!--                >-->
    <!--                  <feather-icon-->
    <!--                    icon="ClockIcon"-->
    <!--                    size="18"-->
    <!--                  />-->
    <!--                </b-avatar>-->
    <!--              </b-media-aside>-->
    <!--              <b-media-body>-->
    <!--                <h6 class="mb-0">-->
    <!--                  <small>{{ 'from ' }}</small>{{ '15:00' }}<br>-->
    <!--                  <small>{{ 'to ' }}</small>{{ '17:00' }}-->
    <!--                </h6>-->

    <!--              </b-media-body>-->
    <!--            </b-media>-->

    <!--      <b-col>-->
    <b-card>
      <div class="position-absolute statuses">

        <b-badge
          v-if="'post_status' in point"
          :variant="`light-${getPostStatusProperty(point.post_status, 'class')}`"
        >
          <small>
            {{ $t(point.post_status) }}
            <feather-icon
              :icon="getPostStatusProperty(point.post_status, 'icon')"
            />
          </small>
        </b-badge>
      </div>

      <b-card-text class="text-left mt-1">
        <!--        <b-badge-->
        <!--          pill-->
        <!--          variant="light-secondary"-->
        <!--        >-->
        <!--          <feather-icon-->
        <!--            icon="EyeIcon"-->
        <!--            class="mr-25"-->
        <!--          />-->
        <!--          <span>-->
        <!--            {{ point.number_of_views }}-->
        <!--          </span>-->
        <!--        </b-badge>-->
        <b-badge
          v-if="point.close_date"
          pill
          :variant="`light-${getPostStatusProperty(point.post_status, 'class')}`"
        >
          <feather-icon
            :icon="getPostStatusProperty(point.post_status, 'icon')"
            class="mr-25"
          />
          {{ $t(point.post_status) }} | {{ point.close_date | formatDateTime() }}
        </b-badge>

        <b-badge
          pill
          variant="light-secondary"
        >
          <small class="text-nowrap">
            <feather-icon
              v-if="point.create_date!==point.update_date"
              icon="Edit3Icon"
              class="mr-25"
            />
            <feather-icon
              v-else
              icon="Edit2Icon"
              class="mr-25"
            />{{ point.update_date | formatDateTime() }}
          </small>
        </b-badge>
        <b-badge
          pill
          variant="warning"
        >
          {{ getTypeTitle(point.point_type.slug) }}
        </b-badge>
        <b-badge
          v-for="purpose in point.point_purposes"
          :key="purpose.slug"
          pill
          variant="light-primary"
        >
          {{ getPurposeTitle(purpose.slug) }}
        </b-badge>
        <b-badge
          v-if="point.number_of_views"
          pill
          variant="light-secondary"
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-25"
          />
          <small>
            {{ point.number_of_views }}
          </small>
        </b-badge>
        <b-badge
          pill
          variant="light-secondary"
        >
          <feather-icon
            icon="GlobeIcon"
            class="mr-25"
          />
          <small>
            {{ getLang(point.lang) }}
          </small>
        </b-badge>
      </b-card-text>
      <b-row>
        <b-col cols="12">
          <offer-media
            v-if="point.media_files.length"
            :media-files="point.media_files"
            :resize-thumbnails="resizeThumbnails"
            class="float-md-right d-flex justify-content-center m-1"
          />
          <offer-general-info
            class="mt-1"
            :point="point"
          />

        </b-col>
        <!--      <b-row>-->
        <!--        <b-col sm="12">-->
        <!--          <h1>-->
        <!--            {{ point.name }}-->
        <!--          </h1>-->
        <!--          <b-media-->
        <!--            v-if="point.description"-->
        <!--            no-body-->
        <!--            class="mb-1"-->
        <!--          >-->
        <!--            <b-media-aside class="d-none d-lg-block">-->
        <!--              <b-avatar-->
        <!--                rounded-->
        <!--                variant="light-primary"-->
        <!--                size="20"-->
        <!--              >-->
        <!--                <feather-icon-->
        <!--                  icon="ClipboardIcon"-->
        <!--                  size="14"-->
        <!--                />-->
        <!--              </b-avatar>-->
        <!--            </b-media-aside>-->
        <!--            <b-media-body-->
        <!--              class="align-self-center"-->
        <!--            >-->
        <!--              <h5 class="mb-0">-->
        <!--                {{ point.description }}-->
        <!--              </h5>-->
        <!--            </b-media-body>-->
        <!--          </b-media>-->
        <!--        </b-col>-->
        <!--        <b-col sm="12">-->
        <!--          <b-media-->
        <!--            no-body-->
        <!--            class="mb-1"-->
        <!--          >-->
        <!--            <b-media-aside class="d-none d-lg-block">-->
        <!--              <b-avatar-->
        <!--                rounded-->
        <!--                variant="light-primary"-->
        <!--                size="20"-->
        <!--              >-->
        <!--                <feather-icon-->
        <!--                  icon="MapPinIcon"-->
        <!--                  size="14"-->
        <!--                />-->
        <!--              </b-avatar>-->
        <!--            </b-media-aside>-->
        <!--            <b-media-body-->
        <!--              class="align-self-center"-->
        <!--            >-->
        <!--              <request-open-map-->
        <!--                v-if="point.address.country !== null"-->
        <!--                :data="point"-->
        <!--              />-->
        <!--              <b-alert-->
        <!--                v-if="point.location_radius"-->
        <!--                show-->
        <!--                variant="dark"-->
        <!--              >-->
        <!--                <div class="alert-body">-->
        <!--                  <feather-icon-->
        <!--                    class="mr-25"-->
        <!--                    icon="InfoIcon"-->
        <!--                    size="16"-->
        <!--                  />-->
        <!--                  <span class="ml-25">{{ $t('Full address is displayed only for the service provider who made the service proposal') }}</span>-->
        <!--                </div>-->
        <!--              </b-alert>-->
        <!--            </b-media-body>-->
        <!--          </b-media>-->
        <!--        </b-col>-->
        <!--      </b-row>-->

      </b-row>

    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCardText, BBadge, BCard,
} from 'bootstrap-vue'

import store from '@/store'
import {
  getFormattedAddress,
  getPostStatusProperty,
} from '@/mixins/functions'
import {
  locales,
  serviceOptions,
} from '@/mixins/options'
import defineAbilityFor from '@/libs/acl/defineAbility'
import { formatDateTime } from '@/filters/dateTime'
import { usePointsUi } from '@/views/apps/service/usePoints'
import { useRouter } from '@core/utils/utils'
import { computed } from '@vue/composition-api'
import OfferGeneralInfo from './OfferGeneralInfo.vue'
import OfferMedia from './OfferMedia.vue'

export default {
  components: {
    OfferGeneralInfo,
    BRow,
    BCol,
    BCardText,
    BBadge,
    BCard,

    OfferMedia,
  },
  filters: {
    formatDateTime,
  },
  mixins: [getFormattedAddress, getPostStatusProperty],
  props: {
    offer: {
      type: Object,
      default: () => {},
      required: false,
    },
  },

  data() {
    return {
      mediaTabIcon: 'CameraOffIcon',
      mediaTabDisabled: true,
    }
  },
  computed: {
    point() {
      return this.offer || store.getters['point/Point']
    },
  },
  watch: {
    point: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value.mediaFiles && value.mediaFiles.length > 0) {
          this.updateMediaTab()
        }
      },
    },
  },
  methods: {
    updateMediaTab() {
      this.mediaTabIcon = 'CameraIcon'
      this.mediaTabDisabled = false
    },
  },
  setup() {
    const user = store.getters['account/user']
    const { getPurposeTitle, getTypeTitle } = serviceOptions
    const ability = defineAbilityFor(user)
    const { isManaged, isOnBehalf, getOwnerProperty } = usePointsUi()
    const ownerProperty = getOwnerProperty()
    const { route } = useRouter()
    const {
      getLang,
    } = locales()

    const resizeThumbnails = computed(() => route.value.hash === '#' || route.value.hash === '')

    return {
      ability,
      user,
      getPurposeTitle,
      getTypeTitle,
      isManaged,
      isOnBehalf,
      ownerProperty,
      resizeThumbnails,
      getLang,
    }
  },
}
</script>
<style lang="scss" scoped>

.statuses{
  top: -1px!important;
  right:0;
  margin-right: 0!important;
}
.statuses>span{
  border-radius: 0;
}
.statuses>span:first-child:last-child {
  border-radius:  0 0.428rem 0 0.428rem;
}
.statuses>span:first-child {
  border-radius:  0 0 0 0.428rem;
}
.statuses>span:last-child {
  border-radius:  0 0.428rem 0 0 ;
}
</style>
