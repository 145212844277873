<template>
  <div>
    <b-card-text>
      <h4>
        {{ point.name }}
      </h4>
    </b-card-text>
    <div
      v-html="point.description"
    />

    <div
      v-if="point.address.country!==null "
      class="d-flex justify-content-start align-items-center mb-75"
    >
      <b-avatar
        rounded
        size="32"
        class="mr-75"
        variant="light-primary"
      >
        <feather-icon
          icon="MapPinIcon"
          size="16"
        />
      </b-avatar>

      <offer-open-map
        :data="point"
      />

    </div>
    <div
      v-if="point.location_radius!==null "
      class="d-flex justify-content-start align-items-center mb-75"
    >
      <b-avatar
        rounded
        size="32"
        class="mr-75"
        variant="light-primary"
      >
        <feather-icon
          icon="RadioIcon"
          size="16"
        />
      </b-avatar>
      <div>
        {{ $t('Within a radius of') }}
        <h6 class="mb-0">
          {{ point.location_radius }} {{ $t('km') }}
        </h6>
      </div>

    </div>

    <!--          <b-media-->
    <!--            no-body-->
    <!--          >-->
    <!--            <b-media-aside class="mr-1">-->
    <!--              <b-avatar-->
    <!--                rounded-->
    <!--                variant="light-primary"-->
    <!--                size="34"-->
    <!--              >-->
    <!--                <feather-icon-->
    <!--                  icon="CalendarIcon"-->
    <!--                  size="18"-->
    <!--                />-->
    <!--              </b-avatar>-->
    <!--            </b-media-aside>-->
    <!--            <b-media-body>-->
    <!--              <h6 class="mb-0">-->
    <!--                Sat, May 25, 2020-->
    <!--              </h6>-->
    <!--              <small>10:AM to 6:PM</small>-->
    <!--            </b-media-body>-->
    <!--          </b-media>-->
  </div>
</template>

<script>
import {
  BCard, BCardText, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import { formatTimeOfStay, getFormattedAddress, getPostStatusProperty } from '@/mixins/functions'
import Ripple from 'vue-ripple-directive'
import { serviceOptions } from '@/mixins/options'
import { formatDateTime } from '@/filters/dateTime'
import OfferOpenMap from './OfferOpenMap.vue'

export default {
  components: {
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    OfferOpenMap,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatDateTime,
  },
  mixins: [formatTimeOfStay, getFormattedAddress, getPostStatusProperty],
  props: {
    point: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup() {
    const { getPurposeTitle } = serviceOptions
    return {
      getPurposeTitle,
    }
  },

}
</script>
<style lang="scss" scoped>

</style>
