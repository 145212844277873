<template>
  <div class="header">
    <b-row class="text-right">
      <b-col class="my-1">
        <div class="actions">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            class="mb-1"
            :to="{ name: 'apps-service-offer-edit', params: { id: point.id } }"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50 d-none d-sm-inline"
            />
            <span class="align-middle">{{ $t('Edit') }}</span>
          </b-button>
        </div></b-col>
      <b-col class="my-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          block
          class="mb-1"
          @click.prevent="deleteOffer(point)"
        >
          <feather-icon
            icon="TrashIcon"
            class="mr-50 d-none d-sm-inline"
          />
          <span class="align-middle">{{ $t('Delete') }}</span>
        </b-button>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import defineAbilityFor from '@/libs/acl/defineAbility'
import { getPostStatusProperty, getUserAvatarText, isMobile } from '@/mixins/functions'
import { showToast } from '@/mixins/notification/toasts'
import router from '@/router'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  mixins: [getPostStatusProperty, getUserAvatarText, showToast],

  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    point() {
      return store.getters['point/Point']
    },
  },
  methods: {
    deleteOffer(point) {
      this.$swal({
        title: this.$t('Are you sure?'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('point/deleteServiceOffer', { id: point.id }).then(response => {
            if ([200, 201, 'success'].includes(response.status)) {
              // this.points.splice(this.points.findIndex(item => item.id === point.id), 1)
              router.go(-1)
              this.showToast(response, 'Offer')
            }
          })
        }
      })
    },
  },

  setup() {
    const user = store.getters['account/user']
    const ability = defineAbilityFor(user)
    return {
      ability,
    }
  },
}

</script>
<style scoped>
  .header .post-status {
    border-right: 1px solid #d8d6de;
    padding-right: 1.3rem;
    margin-right: 1.3rem;
  }
  .header .post-status  {
    border-right-color: #404656;
  }
  .actions>button{
    margin-left: 1.3rem;
  }
  .post-status{
    padding-left: 1em;
    padding-right: 1em;
  }
</style>
